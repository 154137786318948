<template>
  <v-app>
    <v-main :class="{ 'd-none': !loaded }">
      <v-container class="fill-height align-start pa-0">
        <v-row>
          <v-col class="offset-2 col-8 text-center">
            <base-modal
              v-if="selectedReminder"
              :reminder="selectedReminder"
              :dialog="dialog"
              :fullscreen="selectedReminder.isDemo"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import remind from '@/kit/dictionaries/reminders/remind'
import BaseModal from '@/components/BaseModal'
import Layout from '@/mixins/Layout'

export default {
  components: { BaseModal },
  mixins: [Layout],
  data() {
    return {
      dialog: false,
      loaded: false,
      selectedReminder: null,
      activity: this.$route.params.activity,
      choice: this.$route.params.choice
    }
  },
  mounted() {
    if (this.activity) {
      let activity = this.activity
      activity = remind.find(
        r => r.title.replace(' ', '-').toLowerCase() === activity
      )
      if (activity) {
        this.selectedReminder = {
          ...activity,
          trigger: this.choice
        }
        this.loaded = true
        this.dialog = true
      }
    }
  }
}
</script>

<style lang="scss">
.dialog-close-button {
  display: none;
}
</style>
